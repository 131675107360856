.left-off-canvas-wrapper-coperate {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  // opacity: 0;
  // transition: opacity 0.3s ease;

  .backdrop-coperate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.left-off-canvas-coperate {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1160px;
  background-color: $color-white;
  padding: 3.438rem 4.063rem;
  z-index: 600;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translateX(768px);
  transition: all 0.3s ease;

  @include respond(w576) {
    padding: 2rem;
  }

  .header-coperate {
    h3 {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: #000000;
      margin-bottom: 0;
    }

    .close-btn-coperate {
      background-color: transparent;
      padding: 10px;
      @include btn-hover;
    }
  }
}

// .left-off-canvas-wrapper.open {
//   opacity: 100;
// }

.left-off-canvas-coperate.open {
  transform: translateX(0px);

  @include respond(w768) {
    width: 100vw;
  }
}
.cut-btn-email {
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-left: 2px;
}
