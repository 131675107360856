.banner-carousel {
  margin-left: 3rem;
  .img {
    width: 75%;
    height: 25rem !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
  }
}
.i5 {
  position: absolute;
  top: 20.5rem;
  left: 46%;
}
