.success-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 100;
  }
}

.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  background-color: $color-white;
  padding: 2rem;
  z-index: 600;
  width: 35rem;
  height: auto;
  border-radius: 10px;

  @include respond(w992) {
    padding: 1.25rem;
    width: 25rem;
  }

  .close-btn {
    background-color: transparent;

    @include btn-hover;
  }

  .tick {
    width: 10rem;
    height: auto;

    @include respond(w992) {
      width: 7rem;
    }
  }

  h5 {
    color: $color-black-2;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1rem;
  }
}
