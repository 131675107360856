.event-details {
  h5 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.5rem;
    color: $color-black-2;
  }

  p,
  h2 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.5rem;
    color: $color-black-2;
  }

  ol {
    li {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 2.188rem;
      color: $color-gray-2;
    }
  }
}
