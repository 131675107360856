.slide-container {
  width: 80%;
  margin: auto;
  padding-top: 20px;
}

.slide {
  text-align: center;
}

.slide img {
  width: 100%;
  height: auto;
}

.content {
  padding: 10px;
}

.slide-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  @include respond(w576) {
    width: 90%;
  }
}

.slide {
  text-align: center;
}

.slide img {
  width: 100%;
  height: auto;
}

.slide {
  padding: 14px;
  width: 94% !important;
  display: inline-block;
  border: 1px solid #e7eaec;
  border-radius: 6px;
}

.slide_head {
  color: #133240;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.slide_test {
  color: #133240;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.slide_image {
  width: auto !important;
  height: 80px !important;
}

.custom-arrow {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  float: right;
}

.custom-arrow:hover {
  background-color: #0056b3;
}

.custom-arrows {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  float: right;
}

.custom-prev-arrow,
.custom-next-arrow {
  background-color: transparent;
  border: 1px solid #133240;
  color: #133240;
  padding: 10px 16px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 6px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-prev-arrow:hover,
.custom-next-arrow:hover {
  opacity: 50%;
}

.slider_np {
  width: 9px;
  height: 16px;
}

.slider_h {
  font-size: 28px;
  font-weight: 700;
  color: #000000;
  line-height: 39px;
  // @include respond(w576) {
  //   font-size: 22px;
  // }
}

.slider_p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.star_g {
  display: flex;
  align-items: initial;
  float: right;
}

.star {
  display: inline-block;
  margin-right: 2px;
  width: 18px !important;
  height: auto !important;
}

.travel_name {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .slide_head {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
  }

  .travel_name {
    font-size: 11px;
    font-weight: 600;
    text-align: left;
  }

  .slide_test {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  .slide_image {
    width: auto;
    height: 20px;
  }

  .slide_image {
    width: auto !important;
    height: 40px !important;
  }

  .slide_imageImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the entire container */
  }

  .star {
    width: 12px !important;
    height: auto !important;
  }
}
