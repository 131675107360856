.pay-form-specific {
  .total-specific {
    background: #effff9;
    border: 1px dashed #608e7e;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.688rem;
      color: $color-black-2;
      margin-bottom: 0;
    }

    .date {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 140%;
      color: #737373;
    }

    & > div {
      .tickets-count {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.688rem;
        color: #737373;
      }

      .amount {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.688rem;
        color: $color-black-2;
      }
    }
  }

  .stepper-div {
    display: flex;
    align-items: start;
    justify-content: center;
    .step-01 {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5px;
      .stepper {
        height: 22px;
        width: 22px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .active {
        background-color: #00b733;
      }
      .deactive {
        background-color: #e9e9e9;
      }
      .stepper-name {
        color: #a0a0a0;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .dot-line {
      color: #00b733;
    }
    .step-02 {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5px;
      .stepper {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .active {
        background-color: #00b733;
      }
      .deactive {
        background-color: #e9e9e9;
      }
      .stepper-name {
        color: #a0a0a0;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  form {
    width: 100%;

    label {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.313rem;
      color: #000000;
      margin-bottom: 5px;
    }

    input[type='text'],
    input[type='tel'],
    input[type='email'],
    input[type='date'],
    textarea {
      border-radius: 0 !important;
      background: #ffffff;
      border: 1px solid #757d8f;
      transition: border 0.3s ease;

      &:focus {
        border: 1px solid $color-black;
        box-shadow: none;
      }
    }

    select {
      border-radius: 0 !important;
      border: 1px solid #757d8f;

      &:focus {
        border: 1px solid $color-black;
        box-shadow: none;
      }
    }

    input.on-error,
    select.on-error,
    textarea.on-error {
      border: 1px solid $color-red;

      &:focus {
        border: 1px solid $color-red;
      }
    }

    input[type='radio'] {
      &:focus {
        box-shadow: none;
      }
    }

    .logos {
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }
    .pay-btn {
      min-width: 12rem;
      border: none;

      @include respond(w576) {
        min-width: 11rem;
      }
    }
    .next-btn {
      min-width: 6rem;
      border: none;
      background-color: transparent;

      @include respond(w576) {
        min-width: 11rem;
      }
    }
    .email-show {
      padding: 8px;
      border-radius: 5px;
      background: #f1f1f1;
      margin-top: 5px;
      margin-left: 2px;
      color: $color-black-2;
      font-size: 0.75rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .email-grid {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(150px, 1fr)
      ); /* Adjust minmax() values as needed */
      gap: 5px; /* Adjust gap as needed */
    }
    // select:focus {
    //   outline: none; /* Removes the default focus outline */
    //   border-color: initial; /* Resets the border color */
    // }
    .cut-btn-email {
      // margin-top: 2px; /* Adjust margin as needed */
    }
    .form-check-input:checked {
      background-color: #00b733;
    }
  }
}
