.our_story {
  color: #545454;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
}

.our_story_text {
  color: #051b13;
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
}

.oneticket_logo {
  height: 62px;
  width: auto;
}

.about_line {
  margin-top: 20px;
  margin-bottom: 36px;
  border-top: 1px solid #c9c9c9;
  //   transform: matrix(1, 0, 0, 1, 0, 0);
}

.contactus-section {
  width: 100%;
  /* height: 800px; */
  /* background: url('../assets/SVG/vertical-lines.svg'), #F0F9F7; */
  background-color: #f0f9f7;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 0px 70px;
}

.contactus-row {
  padding: 90px 0px;
}

.contactus-details {
  padding: 52px 0;
}

.contactus-details__main-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 170%;
  color: #051b13;
}

.contactus-details__main-para {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 189.5%;
  color: #777777;
}

.contactus-info:not(:last-child) {
  border-right: 1px solid #c2c2c2;
}

.contactus-info__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.contactus-info .footer-socialmedia__item-link {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
}

.contactus-info__details {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.contactus-info__details-img {
  width: 30px;
  height: 30px;
  margin-right: 25px;
}

.contactus-info__details-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  text-decoration: none;
}

@media only screen and (max-width: 854px) {
  .contactus-info {
    flex-direction: column;
    align-items: flex-start !important;
    margin-top: 35px;
  }

  .contactus-info__details-text br {
    display: none;
  }

  .contactus-info:not(:last-child) {
    border-right: 0px solid #c2c2c2;
  }
}

.contactus-detail-section {
  padding: 110px 0px;
}

.contactus-detail__title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #051b13;
  margin-bottom: 20px;
}

.contactus-detail__para {
  color: #3f3f3f;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #718096;
  margin-bottom: 0px;
}

.contactus-detail__logo {
  margin-top: 40px;
}

.contactus-detail__logo img {
  width: 60%;
  height: auto;
  object-fit: cover;
}

.contactus-detail__logo img:not(:last-child) {
  margin-right: 13px;
}

.contactus-detail-form__title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: normal;
  color: #051b13;
  //   margin-bottom: 35px;
}

.contactus-detail-form__para {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 189.5%;
  color: #777777;
  margin-bottom: 40px;
}

.contactus-form-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.3px;
  color: #4f4f4f;
  margin-bottom: 6px;
}

.contactus-form-input {
  height: 56px;
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 9px;
}

.contactus-form-input:focus {
  background: #f4f4f4;
}

.contactus-form-textarea {
  height: 208px;
  background: #f4f4f4;
  border: 1px solid #d7d7d7;
  box-sizing: border-box;
  border-radius: 9px;
}

.contactus-form-textarea:focus {
  background: #f4f4f4;
}

.contact-btn {
  padding: 16px 60px;
  border-radius: 7px;
  background-color: #051b13;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 0.5s;
}

.contact-btn:hover {
  opacity: 0.8;
  color: #fff;
}

@media only screen and (min-width: 0px) and (max-width: 660px) {
  .contactus-form__sec {
    margin-bottom: 55px !important;
  }

  .oneticket_res {
    display: none;
  }
}

.primary-btn2 {
  background: #051b13;
  border-radius: 8px;
  padding: 0.938rem 1.875rem;
  color: #ffffff;
  font-weight: 500;
  width: 198px;
  height: 57px;
  font-size: 1rem;
  font-size: 18px;
}

.say_p {
  font-size: 13px;
  color: #777777;
  font-weight: 400;
}

.label_name {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 500;
}

.iso_img {
  height: 52px;
  width: auto;
}
