.our_story {
  color: #545454;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
}

.our_story_text {
  color: #051b13;
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
}

.oneticket_logo {
  height: 62px;
  width: auto;
}

.about_line {
  margin-top: 20px;
  margin-bottom: 36px;
  border-top: 1px solid #c9c9c9;
  //   transform: matrix(1, 0, 0, 1, 0, 0);
}

.about_h1 {
  color: #051b13;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.about_p1 {
  font-size: 18px;
  font-weight: 400;
  font-size: 18px;
}

@media only screen and (min-width: 0px) and (max-width: 660px) {
  .oneticket_res {
    display: none;
  }
}
