.cart {
  .welcome-note {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 50px;
  }
  &-1 {
    display: flex;
    gap: 75px;

    @include respond(w1200) {
      flex-direction: column;
    }

    // .img {
    //   width: 50%;
    //   min-height: 25rem;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   object-fit: cover;
    //   border-radius: 16px;
    //   overflow: hidden;
    // }

    .img {
      width: 45%;
      border-radius: 16px;
      overflow: hidden;

      @include respond(w1200) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;

        @include respond(w1200) {
          width: 100%;
        }
      }
    }

    .info {
      flex: 1;

      .name {
        font-weight: 600;
        font-size: 2rem;
        line-height: 3.2rem;
        color: $color-black-2;
        margin-bottom: 0;
      }

      .date {
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 2.5rem;
        color: #000000;
      }

      &-1 {
        h5 {
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 2.25rem;
          color: $color-black-2;
          margin-bottom: 0;
        }

        p {
          width: 65%;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 2.188rem;
          color: $color-gray-2;

          @include respond(w768) {
            width: 100%;
          }
        }

        .tickets {
          @include respond(w1200) {
            width: 75%;
          }

          @include respond(w992) {
            width: 100%;
          }

          &-1 {
            .ticket {
              &-1 {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.563rem;
                color: $color-black;
                display: flex;
                align-items: center;
                justify-content: space-between;
                strong {
                  font-weight: 500;
                  font-size: 1.125rem;
                  line-height: 1.563rem;
                  padding-left: 5px;
                }

                p {
                  font-size: 12px;
                  font-weight: 400;
                  color: #959595;
                  line-height: 1.563rem;
                }
              }

              &-2 {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;

                & > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                button {
                  background-color: transparent;
                  transition: scale 0.3 ease;

                  &:hover {
                    transform: scale(1.05);
                  }

                  &:active {
                    transform: scale(1);
                  }
                }

                button:disabled {
                  cursor: default;

                  &:hover {
                    transform: scale(1);
                  }

                  &:active {
                    transform: scale(1);
                  }
                }

                span {
                  font-weight: 400;
                  font-size: 1.125rem;
                  color: $color-gray-4;
                }
              }

              &-3 {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1.563rem;
                color: $color-gray-5;
              }
              &-4 {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.563rem;
                color: $color-black;
                p {
                  font-size: 11px;
                  font-weight: 400;
                  color: #959595;

                  line-height: 1.563rem;
                }
              }
              &-5 {
                font-weight: 500;
                        font-size: 1rem;
                        color: #ff0000;
                        //text-decoration: line-through;
                        display: flex;
                  align-items: center;
                  justify-content: center;
              }
            }
          }

          hr {
            border: 1px solid $color-gray-5;
          }

          .total {
            :first-child {
              font-weight: 600;
              font-size: 1.25rem;
              color: $color-black-2;
            }

            :last-child {
              font-weight: 600;
              font-size: 1.75rem;
              color: $color-black-2;
            }
            .discount {
              font-weight: 500;
              font-size: 1.25rem;
              color: #ff0000;
              margin-right: 15px;
              text-decoration: line-through;
            }
          }

          .buy-btn {
          }
        }
      }
    }
  }

  &-2 {
  }
  input.on-error,
  select.on-error,
  textarea.on-error {
    border: 1px solid $color-red;

    &:focus {
      border: 1px solid $color-red;
    }
  }

  input[type='radio'] {
    &:focus {
      box-shadow: none;
    }
  }
  p.error-text {
    color: $color-red;
    font-size: 0.813rem;
    font-weight: 500;
    margin-top: 0.188rem;
  }
}

.loading-div {
  position: fixed;
  top: 69px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(w576) {
    top: 58.63px;
  }
}
.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 571px;
  height: 351px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  z-index: 3;
  animation: popupAnimation 2s ease-in-out;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @include respond(w768) {
    width: 80%;
  }
}
.confirmation-dialog::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in Chrome, Safari, and newer versions of Edge */
}
.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Change the background color to black with opacity */
  z-index: 2;
}
.confirmation-dialog-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topic {
    font-size: 28px;
    font-weight: 700;
    line-height: 33.89px;
    color: #0b0b0b;
    margin-bottom: 10px;
    text-align: center;
  }
  .sub-topic {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #0046cd;
    margin-bottom: 15px;
  }
}
