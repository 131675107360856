.header {
  .logo {
    img {
      width: 9.063rem;
      @include respond(w576) {
        width: 7.063rem;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.563rem;
    color: $color-black-2;

    @include respond(w576) {
      font-size: 0.75rem;
      line-height: 0.563rem;
    }
  }

  .confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 90%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    z-index: 3;
    animation: popupAnimation 2s ease-in-out;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @include respond(w768) {
      width: 80%;
    }
  }

  .confirmation-dialog::-webkit-scrollbar {
    display: none;
    /* Hide the scrollbar in Chrome, Safari, and newer versions of Edge */
  }

  .confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Change the background color to black with opacity */
    z-index: 2;
  }

  .pay-btn {
    min-width: 12rem;

    @include respond(w576) {
      min-width: 8rem;
    }
  }

  .reach_out {
    font-size: 20px;
    font-weight: 600;
    text-align: center;

    @include respond(w576) {
      font-size: 16px;
    }
  }

  .say_hello {
    font-size: 16px;
    color: limegreen;
    padding-bottom: 10px;
  }

  @keyframes popupAnimation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }

    50% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.05);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.active-link p {
  color: #009834;
}

.menu_space {
  margin-left: 20px;
}
