@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 100%; /* 1rem = 16px */
  scroll-behavior: smooth;

  @include respond(w576) {
    font-size: 85%;
  }
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: $primary-font, sans-serif;
  background: linear-gradient(140.46deg, #f6fff2 7.18%, #fffefb 101.41%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
