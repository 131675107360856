.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
    border-bottom: 1px solid #e3e3e3;
    background-color: $color-white;
  }

  main {
    padding-top: calc(6rem + 80px);
    padding-bottom: 8rem;
  }

  footer {
    margin-top: auto;
  }
}
