// breakpoints
@mixin respond($breakpoint) {
  @if $breakpoint == w2560 {
    @media only screen and (max-width: 2560px) {
      @content;
    }
  }
  @if $breakpoint == w1920 {
    @media only screen and (max-width: 1920px) {
      @content;
    }
  }
  @if $breakpoint == w1680 {
    @media only screen and (max-width: 1680px) {
      @content;
    }
  }
  @if $breakpoint == w1440 {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }
  @if $breakpoint == w1366 {
    @media only screen and (max-width: 1366px) {
      @content;
    }
  }
  @if $breakpoint == w1280 {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
  @if $breakpoint == w1200 {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }
  @if $breakpoint == w992 {
    @media only screen and (max-width: 992px) {
      @content;
    }
  }
  @if $breakpoint == w967 {
    @media only screen and (max-width: 967px) {
      @content;
    }
  }
  @if $breakpoint == w768 {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == w576 {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }
  @if $breakpoint == w400 {
    @media only screen and (max-width: 400px) {
      @content;
    }
  }
}

// center elements
@mixin center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  object-fit: contain;
}

// button hover style
@mixin btn-hover {
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0px);
  }
}
