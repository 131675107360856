$primary-font: 'Poppins';

// colors
$color-black: #000000;
$color-black-2: #051b13;
$color-white: #ffffff;
$color-gray: #616161;
$color-gray-2: #616161;
$color-gray-3: #5e5e5e;
$color-gray-4: #7b7b7b;
$color-gray-5: #959595;
$color-blue: #004c93;
$color-red: #ff0000;
$color-purple: #8f00ff;
$color-green: #55c699;

:root {
  --font-main: #{$primary-font}, sans-serif;
  --color-black: #{$color-black};
  --color-black-2: #{$color-black-2};
  --color-white: #{$color-white};
  --color-gray: #{$color-gray};
  --color-gray-2: #{$color-gray-2};
  --color-gray-3: #{$color-gray-3};
  --color-gray-4: #{$color-gray-4};
  --color-gray-5: #{$color-gray-5};
  --color-blue: #{$color-blue};
  --color-red: #{$color-red};
  --color-purple: #{$color-purple};
  --color-green: #{$color-green};
}
