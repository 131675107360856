.footer {
  &-1 {
    .social-link {
      background: #fafafa;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      width: 34px;
      height: 34px;

      @include btn-hover;
    }

    .info > p {
      font-weight: 500;
      font-size: 1rem;
      color: $color-gray-3;
    }
  }

  &-2 {
    background-color: #051b13;

    p {
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-white;
    }
  }
}

.footer {
  background-color: #051b13;
}

.footer__top {
  padding: 79px 0px 111px;
}

.footer__top-section {
  margin-bottom: 50px;
}

.footer__horizontal_line {
  color: white;
}

@media only screen and (min-width: 854px) {
  .footer__top-section {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 495px) {
  .footer__top {
    padding: 79px 20px 111px !important;
  }
}

.footer-socialmedia {
  list-style: none;
  padding-left: 0px;
  display: flex;
  margin-bottom: 0px;
}

.footer-socialmedia__item:not(:last-child) {
  margin-right: 15px;
}

.footer-socialmedia__item-link {
  width: 34px;
  height: 34px;
  padding: 10px 17px;
  background-color: #ffffff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
}

.footer__logo {
  height: 36px;
  width: auto;
  margin-bottom: 30px;
}

/* Footer Style End*/

.ft {
  width: 100%;
  height: 600px;
  background-color: #051b13;
}

.footer__flex {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-between;
}

.footer__flex_content {
  width: 24%;
  height: 500px;
}

.footer_a_tag {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  color: #cfcfcf;
}

.footer__links_heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
  padding: 0 0 30px;
}

.footer__socialmedia_logos {
  padding: 73px 0px;
}

.footer__paragraph {
  color: #cfcfcf;
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
}

.footer__copyrigh_paragraph {
  font-size: 14px;
  line-height: 10px;
  color: #fff;
  text-align: center;
  padding: 37px 0px;
  margin-bottom: 0;
}

.footer__horizontal_line {
  border-top: 1px solid #8e8e8e;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.footer__download {
  padding: 0px 11px;
}
