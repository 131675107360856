a {
  color: inherit;
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;

  :focus {
    outline: none;
  }
}

p {
  margin-bottom: 0;
}

.primary-btn {
  background: $color-black-2;
  border-radius: 8px;
  padding: 0.938rem 1.875rem;
  color: $color-white;
  font-weight: 500;
  font-size: 1rem;

  &:hover,
  &:focus {
    background: $color-black-2 !important;
    color: $color-white !important;
    transform: translateY(-1px);
  }

  &:active {
    background: $color-black-2 !important;
    color: $color-white !important;
    transform: translateY(0px);
  }

  &:disabled {
    background: $color-black-2 !important;
    color: $color-white !important;
  }

  @include respond(w576) {
    padding: 0.9rem 1.375rem;
  }
}
.primary-btn3 {
  background: $color-green;
  //border-radius: 8px;
  padding: 0.938rem 1.875rem;
  margin-right: 5px;
  color: $color-white;
  font-weight: 500;
  font-size: 1rem;

  &:hover,
  &:focus {
    background: $color-green !important;
    color: $color-white !important;
    transform: translateY(-1px);
  }

  &:active {
    background: $color-green !important;
    color: $color-white !important;
    transform: translateY(0px);
  }

  &:disabled {
    background: $color-green !important;
    color: $color-white !important;
  }

  @include respond(w576) {
    padding: 0.9rem 1.375rem;
  }
}

.coupon-input {
  border-radius: 0px;
  padding: 0.87rem 1rem;
  margin: 0px 5px 0px 0px;
}

.primary-btn2 {
  background: $color-black-2;
  padding: 8px;
  color: $color-white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $color-black-2 !important;
    color: $color-white !important;
    transform: translateY(-1px);
  }

  &:active {
    background: $color-black-2 !important;
    color: $color-white !important;
    transform: translateY(0px);
  }

  &:disabled {
    background: $color-black-2 !important;
    color: $color-white !important;
  }

  @include respond(w576) {
    padding: 6px 1px;
  }
}
.no-scroll {
  height: 100%;
  overflow: hidden;
}

// form validation
.required-sign {
  color: $color-red;
  font-size: 0.9rem;
  font-weight: 500;
}
.remaining-emails {
  color: $color-red;
  font-size: 0.8rem;
  font-weight: 400;
}
.error-text {
  color: $color-red;
  font-size: 0.813rem;
  font-weight: 500;
  margin-top: 0.188rem;
}
//
.ticket_price {
  color: $color-purple;
  size: 14px;
  font-weight: 500;
}
